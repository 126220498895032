<template>
	<svg :width="width" :height="height" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect x="1.5" y="1.5" width="47" height="47" rx="3.5" :stroke="color" stroke-width="3"/>
		<path d="M26.0742 9.02148H22.4609V22.6445H25.6836C25.8789 22.6445 26.0742 22.6445 26.2695 22.6445C29.7526 22.3841 31.4941 20.0892 31.4941 15.7598C31.4941 15.304 31.4779 14.8646 31.4453 14.4414C31.0547 10.8281 29.2643 9.02148 26.0742 9.02148ZM27.3438 37H12.5488V35.6816H16.4551V9.02148H12.5488V7.70312H28.2715C30.4525 7.70312 32.3079 8.07747 33.8379 8.82617C36.5723 10.1608 37.9395 12.3743 37.9395 15.4668C37.9395 16.8014 37.679 18.0221 37.1582 19.1289C35.5957 22.4492 32.0638 24.1094 26.5625 24.1094H22.4609V35.6816H26.0254C26.709 35.7467 27.1484 36.1862 27.3438 37Z" :fill="color"/>
	</svg>
</template>

<script>
export default {
	name: 'ParkingIcon',
	props: {
		width: {
			type: Number,
			default: 50,
		},
		height: {
			type: Number,
			default: 50,
		},
		color: {
			type: String,
			default: 'white',
		},
	},
}
</script>