<template>
	<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" :width="width" :height="height" viewBox="0 0 256 256" xml:space="preserve">
		<g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
			<path d="M 63.291 86.184 c -4.532 0 -8.79 -1.868 -11.99 -5.258 c -1.686 -1.788 -3.923 -2.772 -6.3 -2.772 h 0 c -2.377 0 -4.614 0.985 -6.301 2.772 c -3.199 3.39 -7.457 5.258 -11.989 5.258 c -4.533 0 -8.791 -1.868 -11.991 -5.258 c -6.567 -6.961 -6.567 -18.287 0 -25.247 l 9.444 -10.704 c 5.45 -6.176 12.85 -9.578 20.837 -9.578 c 0 0 0 0 0 0 c 7.988 0 15.387 3.402 20.836 9.578 l 9.455 10.717 c 6.554 6.962 6.55 18.278 -0.012 25.234 C 72.081 84.316 67.823 86.184 63.291 86.184 z" :fill="color" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
			<path d="M 32.017 29.027 c -5.059 0 -8.939 -3.475 -10.197 -9.392 c -1.576 -7.413 1.775 -14.428 7.47 -15.638 c 5.696 -1.215 11.609 3.836 13.184 11.248 c 1.461 6.871 -1.649 12.172 -7.922 13.506 C 33.68 28.936 32.833 29.027 32.017 29.027 z" :fill="color" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
			<path d="M 57.984 29.027 c -0.817 0 -1.663 -0.091 -2.535 -0.276 c -6.273 -1.334 -9.383 -6.635 -7.923 -13.506 c 1.576 -7.413 7.491 -12.461 13.185 -11.248 c 5.694 1.21 9.045 8.226 7.47 15.638 C 66.922 25.551 63.042 29.027 57.984 29.027 z" :fill="color" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
			<path d="M 78.62 45.514 c -1.555 0 -3.154 -0.363 -4.698 -1.083 c -2.678 -1.249 -4.642 -3.317 -5.529 -5.824 c -0.961 -2.716 -0.667 -5.87 0.848 -9.118 c 3.203 -6.868 10.1 -10.454 15.377 -7.994 c 5.276 2.46 6.963 10.05 3.76 16.918 v 0 c -1.515 3.249 -3.742 5.5 -6.44 6.51 C 80.883 45.318 79.763 45.514 78.62 45.514 z" :fill="color" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
			<path d="M 11.38 45.514 c -1.143 0 -2.262 -0.196 -3.318 -0.591 c -2.698 -1.01 -4.925 -3.261 -6.44 -6.51 c -3.202 -6.868 -1.516 -14.457 3.76 -16.918 c 5.277 -2.458 12.175 1.126 15.377 7.995 c 1.515 3.249 1.808 6.401 0.847 9.118 c -0.887 2.507 -2.85 4.575 -5.529 5.824 C 14.534 45.151 12.935 45.514 11.38 45.514 z" :fill="color" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
		</g>
	</svg>
</template>

<script>
export default {
	name: 'PawIcon',
	props: {
		width: {
			type: Number,
			default: 50,
		},
		height: {
			type: Number,
			default: 50,
		},
		color: {
			type: String,
			default: 'white',
		},
	},
}
</script>