<template>
	<div 
		class="relative w-full h-[80vh] grid place-content-center overflow-hidden"
		@mouseenter="startAnimation"
		@mouseleave="stopAnimation"
	>
		<div 
			class="
				cursor-pointer
				absolute top-0 left-0 w-full h-full z-0
				grid grid-rows-2 grid-cols-2 md:grid-cols-3 grid-flow-row
			"
			@click="galleryOpen = true"
		>
			<div 
				v-for="image in galleryPreview"
				:key="image"
				class="gallery-scale bg-cover bg-center"
				:style="{ backgroundImage: `url(${image})` }"
			></div>

			<div
				class="absolute top-0 left-0 w-full h-full crt z-10"
			></div>

			<div
				class="absolute top-0 left-0 w-full h-full bg-[radial-gradient(ellipse_at_center,_black,_transparent)] z-20"
			></div>
		</div>

		<div class="w-full grid bg-[radial-gradient(ellipse_at_center,_black,_transparent)] z-20 cursor-pointer pointer-events-none">
			<span class="gallery-scale capitalize font-display italic text-4xl lg:text-6xl font-medium text-white my-auto">
				{{ $t('global.gallery') }}
			</span>
		</div>

		<PhotoGallery :gallery-open="galleryOpen" @close="galleryOpen = false" />
	</div>
</template>

<script>
import { gsap } from 'gsap';
import { galleryPreview } from '../assets/config/galleryConfig';
import PhotoGallery from './graphics/PhotoGallery.vue';

export default {
	name: 'GalleryPage',
	setup() {
		return {
			galleryPreview,
		};
	},
	components: {
		PhotoGallery,
	},
	data() {
		return {
			galleryOpen: false,
		};
	},
	methods: {
		startAnimation() {
			gsap.to('.gallery-scale', {
				scale: 1.2,
				duration: 1,
				ease: 'power2.out',
			});
		},
		stopAnimation() {
			gsap.to('.gallery-scale', {
				scale: 1,
				duration: 1,
				ease: 'power2.out',
			});
		},
	},
};
</script>