<template>
	<svg 
		xmlns="http://www.w3.org/2000/svg" 
		xmlns:xlink="http://www.w3.org/1999/xlink" 
		version="1.1" 
		:width="width" 
		:height="height" 
		viewBox="0 0 256 256" 
		xml:space="preserve"
	>
		<g 
			style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;"
			transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"	
		>
			<path d="M 82.839 90 c 1.792 0 3.583 -0.684 4.95 -2.05 c 2.734 -2.734 2.734 -7.166 0 -9.9 L 54.738 45 l 33.05 -33.05 c 2.734 -2.733 2.734 -7.166 0 -9.899 c -2.733 -2.733 -7.166 -2.733 -9.899 0 l -38 38 c -2.733 2.733 -2.733 7.166 0 9.9 l 38 38 C 79.256 89.316 81.047 90 82.839 90 z" :fill="color" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
		</g>
	</svg>
</template>

<script>
export default {
	name: 'ArrowLeftIcon',
	props: {
		width: {
			type: Number,
			default: 50,
		},
		height: {
			type: Number,
			default: 50,
		},
		color: {
			type: String,
			default: 'white',
		},
	},
}
</script>