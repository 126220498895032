import { createApp } from 'vue'
import router from './router';

import { createI18n } from 'vue-i18n'
import translations from '@/assets/translations/translations';

import App from './App.vue'
import './assets/tailwind.css'
import './assets/app.css'

const i18n = createI18n({
	locale: 'gb',
	fallbackLocale: 'gb',
	messages: translations,
});

const app = createApp(App);
  
app.use(i18n)
app.use(router)
app.mount('#app')