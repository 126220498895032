<template>
	<div id="app-wrapper" class="bg-back-white">
		<RouterView />
	</div>
</template>

<script>
import { RouterView } from 'vue-router';

export default {
	name: 'App',
	components: {
		RouterView,
	},
}
</script>
