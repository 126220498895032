<template>
	<div class="max-w-3xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
		<h1 class="text-3xl font-bold text-gray-900 mb-6">Privacy Policy</h1>
		<p class="text-sm text-gray-500 mb-8">Last Updated: 27/08/2024</p>

		<p class="mb-6">www.house-ivana.com ("we," "our," or "us") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, and share your personal information when you visit our website and use our booking request form.</p>

		<h2 class="text-2xl font-semibold text-gray-800 mb-4">1. Information We Collect</h2>
		<p class="mb-4">When you submit a booking request through our website, we collect the following information:</p>
		<ul class="list-disc list-inside mb-6">
			<li><strong>Name:</strong> To identify who is making the booking.</li>
			<li><strong>Email Address:</strong> To communicate with you about your booking.</li>
			<li><strong>Number of Guests:</strong> To process your booking request.</li>
			<li><strong>Check-in and Check-out Dates:</strong> To confirm your booking details.</li>
			<li><strong>Optional Message:</strong> Any additional information you wish to provide.</li>
		</ul>

		<h2 class="text-2xl font-semibold text-gray-800 mb-4">2. How We Use Your Information</h2>
		<p class="mb-6">We use the information you provide to:</p>
		<ul class="list-disc list-inside mb-6">
			<li>Process and manage your booking request.</li>
			<li>Communicate with you regarding your booking or inquiries.</li>
		</ul>

		<h2 class="text-2xl font-semibold text-gray-800 mb-4">3. Legal Basis for Processing (For EU Users)</h2>
		<p class="mb-6">If you are located in the European Union, we rely on the following legal bases for processing your personal information under the General Data Protection Regulation (GDPR):</p>
		<ul class="list-disc list-inside mb-6">
			<li><strong>Performance of a Contract:</strong> We process your personal data to fulfill our contractual obligations to you when you request a booking.</li>
			<li><strong>Consent:</strong> If you provide optional information (such as a message), we rely on your consent to process that data. You may withdraw your consent at any time by contacting us.</li>
			<li><strong>Legitimate Interests:</strong> We may process your personal information for our legitimate interests, such as improving our services, provided these interests do not override your data protection rights.</li>
		</ul>

		<h2 class="text-2xl font-semibold text-gray-800 mb-4">4. Data Storage and Security</h2>
		<p class="mb-6">We do not store your personal information on our servers. The data you provide is sent directly to our email address for processing. We only retain a timestamp indicating when the email was sent.</p>

		<h2 class="text-2xl font-semibold text-gray-800 mb-4">5. Sharing Your Information</h2>
		<p class="mb-6">We do not sell, trade, or otherwise transfer your personal information to outside parties.</p>

		<h2 class="text-2xl font-semibold text-gray-800 mb-4">6. Your Rights Under GDPR (For EU Users)</h2>
		<p class="mb-6">If you are located in the EU, you have the following rights under GDPR:</p>
		<ul class="list-disc list-inside mb-6">
			<li><strong>Access:</strong> You have the right to request access to the personal data we hold about you.</li>
			<li><strong>Rectification:</strong> You have the right to request correction of any inaccurate or incomplete personal data we hold about you.</li>
			<li><strong>Erasure:</strong> You have the right to request that we delete your personal data when it is no longer necessary for the purposes for which it was collected.</li>
			<li><strong>Restriction of Processing:</strong> You have the right to request that we restrict the processing of your personal data under certain circumstances.</li>
			<li><strong>Data Portability:</strong> You have the right to request that we provide you with your personal data in a structured, commonly used, and machine-readable format, and to transfer that data to another data controller.</li>
			<li><strong>Objection:</strong> You have the right to object to our processing of your personal data based on legitimate interests or direct marketing purposes.</li>
			<li><strong>Withdrawal of Consent:</strong> If we process your personal data based on your consent, you have the right to withdraw that consent at any time.</li>
		</ul>

		<h2 class="text-2xl font-semibold text-gray-800 mb-4">7. International Data Transfers</h2>
		<p class="mb-6">Your personal information may be transferred to and processed in countries outside of the European Economic Area (EEA). We ensure that appropriate safeguards are in place to protect your personal data in such cases, in accordance with GDPR requirements.</p>

		<h2 class="text-2xl font-semibold text-gray-800 mb-4">8. Changes to This Privacy Policy</h2>
		<p class="mb-6">We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated revision date.</p>

		<h2 class="text-2xl font-semibold text-gray-800 mb-4">9. Contact Us</h2>
		<p class="mb-6">If you have any questions about this Privacy Policy or your personal information, or if you wish to exercise your rights under GDPR, please contact us at:</p>
		<p class="font-semibold">lukas@studio31.hr</p>
	</div>
</template>

<script>
export default {
	name: 'PrivacyPolicyView',
}
</script>