<template>
	<div id="request" class="relative w-full py-10 md:px-10">
		<VerticalLines />
		
		<div class="flex flex-col justify-start items-start gap-8 w-full h-full">
			<div class="w-full lg:w-3/4 xl:w-4/5 xl:gap-2 grid grid-rows-2 xl:grid-cols-2 xl:grid-rows-1">
				<div class="relative flex flex-col justify-start items-start gap-4 w-full px-10 md:px-0">
					<span class="text-xl md:text-2xl lg:text-3xl font-semibold font-display">
						{{ $t('request.title') }}
					</span>
					<div ref="form" class="flex flex-col gap-2 lg:gap-3 w-full md:w-4/5 lg:w-full">
						<TextInput
							:label="$t('request.name')"
							v-model="name"
							@update:model="name = $event"
						/>
						<TextInput 
							:label="$t('request.email')"
							v-model="email"
							@update:model="email = $event"
						/>
						<TextInput 
							:label="$t('request.guests')"
							v-model="guests"
							@update:model="guests = $event"
						/>

						<DateFromTo 
							:label="$t('request.checkInOut')"
							:min-from="new Date().toISOString().split('T')[0]"
							:value-from="checkIn"
							:value-to="checkOut"
							@update:from="checkIn = $event"
							@update:to="checkOut = $event"
						/>

						<TextareaInput
							:label="$t('request.message')"
							v-model="message"
							@update:model="message = $event"
						/>
						
						<div class="relative w-full h-fit">
							<Btn
								class="max-w-60 p-1 mt-6"
								:disabled="isSubmitDisabled || loading"
								@click="submitRequest"
							>
								<span v-if="!loading" class="text-white">
									{{ $t('request.submit') }}
								</span>
								<span v-else class="text-white">
									{{ $t('request.sending') }}
								</span>
							</Btn>

							<a href="/privacy-policy">
								<span class="text-sm absolute bottom-0 left-0 w-fit translate-y-full text-gray-400">
									{{ $t('request.privacyPolicy') }}
								</span>
							</a>
						</div>

						<p v-if="error" class="text-red-500 text-sm w-fit">
							{{ $t('request.error') }}
						</p>
					</div>
					<div ref="submittedBox" class="absolute top-0 left-0 grid place-content-center w-full h-full opacity-0 pointer-events-none">
						<div class="border-2 border-blue-1 text-center rounded p-4 bg-white">
							<span class="text-2xl font-semibold">
								{{ $t('request.submitted') }}
							</span>
						</div>
					</div>
				</div>

				<div class="grid place-content-center relative h-fit my-auto">
					<Swiper
						@swiper="onSwiper"
						@slideChange="onSlideChange"
						:modules="modules"
						:slides-per-view="1.5"
						:space-between="10"
						:mousewheel="true"
						:pagination="{ clickable: true }"
						:navigation="{ enabled: true, nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' }"
						class="w-full py-10"
						:effect="'coverflow'"
						:centered-slides="true"
						:coverflowEffect="{
							'rotate': 0,
							'stretch': 0,
							'depth': 30,
							'modifier': 20,
							'slideShadows': false,
						}"
					>
						<SwiperSlide v-for="review, i in reviews" :key="i" class="w-fit mx-auto">
							<GuestReview :review="review" class="mx-auto"/>
						</SwiperSlide>
					</Swiper>

					<div class="hidden md:block swiper-button-prev absolute z-10 top-0 left-0 w-1/5 h-full bg-gradient-to-r from-white to-transparent">
						<div class="w-full h-full grid place-content-center cursor-pointer transition-all hover:scale-[1.1] active:scale-[0.95]">
							<ArrowLeftIcon v-if="!isBeginning" :width="25" :height="25" color="black" />
						</div>
					</div>
					<div class="hidden md:block swiper-button-next absolute z-10 top-0 right-0 w-1/5 h-full bg-gradient-to-r from-transparent to-white">
						<div class="w-full h-full grid place-content-center cursor-pointer transition-all hover:scale-[1.1] active:scale-[0.95]">
							<ArrowRightIcon v-if="!isEnd" :width="25" :height="25" color="black" />
						</div>
					</div>
				</div>
			</div>

			<div class="flex justify-start items-center gap-1 px-10 md:px-0">
				<span class="text-sm font-normal text-gray-400">
					{{ $t('request.alreadyVisited') }}
				</span>
				<a :href="reviewSources['google'].reviewLink" target="_blank">
					<span class="text-sm font-normal text-blue-500">
						{{ $t('request.leaveReview') }}
					</span>
				</a>
			</div>
		</div>
	</div>
</template>

<script>
import gsap from 'gsap';
import TextInput from './form/TextInput.vue';
import TextareaInput from './form/TextareaInput.vue';
import DateFromTo from './form/DateFromTo.vue';
import Btn from './form/Btn.vue';
import VerticalLines from './graphics/VerticalLines.vue';
import GuestReview from './graphics/GuestReview.vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Pagination, EffectCoverflow } from 'swiper/modules';
import { reviews, reviewSources } from '../assets/config/guestReviews';
import HttpManager from '@/utils/HttpManager';
import { infoConfig } from '@/assets/config/infoConfig';
import ArrowLeftIcon from '@/components/icons/ArrowLeftIcon.vue';
import ArrowRightIcon from '@/components/icons/ArrowRightIcon.vue';

import { analytics } from '@/firebase';
import { logEvent } from 'firebase/analytics';

import 'swiper/scss';
import 'swiper/scss/effect-coverflow';

export default {
	name: 'RequestPage',
	components: {
		VerticalLines,
		TextInput,
		DateFromTo,
		TextareaInput,
		Btn,
		GuestReview,
		Swiper,
		SwiperSlide,
		ArrowLeftIcon,
		ArrowRightIcon,
	},
	setup() {
		return {
			reviews,
			reviewSources,
		};
	},
	data() {
		return {
			name: '',
			email: '',
			guests: '',
			checkIn: '',
			checkOut: '',
			message: '',

			modules: [Navigation, Pagination, EffectCoverflow],
			swiperRef: null,
			isBeginning: true,
			isEnd: false,

			error: false,
			loading: false,
		};
	},
	computed: {
		isSubmitDisabled() {
			return !this.name || !this.email || !this.guests || !this.checkIn || !this.checkOut;
		},
	},
	methods: {
		onSwiper(swiper) {
			this.swiperRef = swiper;
		},
		onSlideChange() {
			this.isBeginning = this.swiperRef?.isBeginning;
			this.isEnd = this.swiperRef?.isEnd;
		},
		prepareDate(date) {
			const arr = date.split("-");

			return `${arr[2]}.${arr[1]}.${arr[0]}`;
		},
		prepareMsg() {
			return `New booking request from ${this.name}:` + '\n'
				+ `Email: ${this.email}` + '\n'
				+ `Guests: ${this.guests}` + '\n'
				+ `Check-in: ${this.prepareDate(this.checkIn)}` + '\n'
				+ `Check-out: ${this.prepareDate(this.checkOut)}` + '\n'
				+ `Message: \n\n${this.message}`;
		},
		async submitRequest() {
			if (this.isSubmitDisabled) {
				return;
			}

			this.loading = true;
			this.error = false;

			const emailData = {
				authKey: infoConfig.authKey,
				subject: "New booking request",
				message: this.prepareMsg(),
			};

			try {
				const result = await HttpManager.post('/send-email', emailData);

				if (result.data?.data?.success || result.data?.success) {
					this.submittedAnimation();

					logEvent(analytics, 'request_sent', {
						name: this.name,
						email: this.email,
						guests: this.guests,
						checkIn: this.checkIn,
						checkOut: this.checkOut,
						message: this.message,
					});
				} else {
					this.error = true;
				}
			} catch (error) {
				this.error = true;
			} finally {
				this.loading = false;
			}
		},
		submittedAnimation() {
			gsap.to(this.$refs.form, {
				opacity: 0,
				duration: 0.5,
				ease: 'power2.out',
			});

			gsap.to(this.$refs.submittedBox, {
				opacity: 1,
				duration: 0.5,
				ease: 'power2.out',
			});
		},
	},
};
</script>