/** @type {import('tailwindcss').Config} */
module.exports = {
	content: [],
	purge: ['./index.html', './src/**/*.{vue,js,ts,jsx,tsx}'],
	theme: {
		fontFamily: {
			'display': ['Playfair Display'],
			'body': ['Lato'],
		},
		extend: {
			colors: {
				'blue-1': '#4FA2DE',
				'blue-2': '#98C6E7',
				'blue-3': '#E4F4FF',
				'brown-1': '#7D6E49',
				'brown-2': '#E0D7C1',
				'brown-3': '#DDC791',

				'back-white': "#FFFEFB",
				pastel: {
					'red': '#ff7f7f',
					'orange': '#ffb07f',
					'green': '#7fff7f',
					'blue': '#7fbfff',
					'purple': '#bf7fff',
					'pink': '#ff7fbf',
					'teal': '#7fffbf',
				},
			},
		},
	},
	plugins: [],
}

