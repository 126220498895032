
<template>
	<div ref="landingPage" class="relative w-full h-screen grid place-content-center">
		<div 
			class="absolute top-0 left-0 w-full h-full z-0"
		>
			<div class="relative w-full h-full overflow-hidden">
				<div
					class="absolute top-0 left-0 w-full h-full bg-cover bg-center z-0 hidden md:block" 
					style="background-image: url('images/front-3.png');"
				></div>

				<div
					class="absolute top-0 left-0 w-full h-full bg-cover bg-center z-0 block md:hidden" 
					style="background-image: url('images/house/8.jpg');"
				></div>

				<div
					class="absolute top-0 left-0 w-full h-full crt z-10"
				></div>
			</div>
		</div>

		<div class="w-screen h-screen bg-[radial-gradient(ellipse_at_center,_black,_transparent)] grid z-20">
			<div class="relative w-fit h-fit mt-auto mx-auto">
				<span class="font-display italic text-5xl md:text-6xl lg:text-8xl font-medium text-white mx-auto mt-auto">
					{{ infoConfig.name }}
				</span>

				<div class="absolute bottom-0 left-1/2 -translate-x-1/2 translate-y-full flex justify-center items-center gap-2">
					<StarSharpIcon 
						v-for="i in infoConfig.stars" 
						:key="i" 
						:width="20" 
						:height="20" 
						color="white"
						class="mt-2"
					/>
				</div>
			</div>

			<div class="flex flex-col justify-center items-center w-fit h-fit gap-2 m-auto">
				<div class="flex justify-center items-center gap-4 h-12 scale-[0.7] md:scale-[0.8] lg:scale[1]">
					<div class="flex justify-center items-center gap-2 h-full">
						<ProfileIcon :width="40" :height="40" color="white" />

						<span class=" text-white text-2xl">
							{{ infoConfig.guests }} + {{ infoConfig.extraGuests }}
						</span>
					</div>
					<div class="h-full grid place-content-center px-4 border-l border-r border-white">
						<ParkingIcon :width="40" :height="40" color="white" />
					</div>
					<div class="h-full grid place-content-center pr-4 border-r border-white">
						<PoolIcon class="mx-auto" :width="50" :height="50" color="white" />
					</div>
					<div class="h-full grid place-content-center pr-4">
						<PawIcon class="mx-auto" :width="40" :height="40" color="white" />
					</div>
				</div>

				<Btn
					class="p-2 w-4/5 lg:w-full"
					color="transparent"
					:border="'border border-white'"
					@click="scrollToRequest"
				>
					<span class="text-white text-xl md:text-2xl font-medium">
						{{ $t('global.bookNow') }}
					</span>
				</Btn>
			</div>
		</div>
	</div>
</template>

<script>
import PoolIcon from './icons/PoolIcon.vue';
import ParkingIcon from './icons/ParkingIcon.vue';
import ProfileIcon from './icons/ProfileIcon.vue';
import PawIcon from './icons/PawIcon.vue';
import StarSharpIcon from './icons/StarSharpIcon.vue';
import Btn from './form/Btn.vue';

import { infoConfig } from '@/assets/config/infoConfig';

export default {
	name: 'LandingPage',
	components: {
		PoolIcon,
		ParkingIcon,
		ProfileIcon,
		PawIcon,
		Btn,
		StarSharpIcon,
	},
	setup() {
		return {
			infoConfig,
		};
	},
	methods: {
		scrollToRequest() {
			document.getElementById('request').scrollIntoView({
				behavior: 'smooth',
			});
		},
	},
}
</script>

<style>
.crt::before {
	content: " ";
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: linear-gradient(rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.2) 50%), linear-gradient(90deg, rgba(255, 0, 0, 0.06), rgba(0, 255, 0, 0.02), rgba(0, 0, 255, 0.06));
	z-index: 2;
	background-size: 100% 2px, 3px 100%;
	pointer-events: none;
}
</style>