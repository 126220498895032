<template>
	<div class="w-full max-w-md flex flex-col justify-center items-start bg-gray-50 rounded-md shadow-md p-4 gap-4">
		<div class="flex justify-start items-center gap-4">
			<div class="w-10 md:w-12 h-10 md:h-12 rounded-full grid place-content-center" :class="getRandomPastelColor()">
				<span class="text-white opacity-80 text-xl md:text-2xl">
					{{ review.name.at(0) }}
				</span>
			</div>

			<div class="flex flex-col justify-start items-start">
				<span class="text-md md:text-lg font-semibold">
					{{ review.name }}
				</span>
				<span class="text-xs font-normal text-gray-400">
					{{ review.date }}
				</span>
			</div>
		</div>

		<div class="flex flex-col items-start justify-center">
			<div class="flex justify-start items-center md:gap-1">
				<StarIcon v-for="i in review.rating" :key="i" :width="20" :height="20" color="gold" class=" scale-[0.8] md:scale-[1]" />
			</div>
			<span class="text-sm md:text-md font-normal text-gray-600">
				{{ $t(`${review.comment}`) }}
			</span>
		</div>


		<a :href="reviewSources[review.source].link" target="_blank">
			<div class="flex items-start justify-center gap-4">
				<div class="w-10 md:w-12 h-10 md:h-12 p-1 grid place-content-center">
					<img :src="reviewSources[review.source].icon" alt="source" class="w-full h-full" />
				</div>

				<div class="flex flex-col justify-start items-start">
					<span class="text-xs font-normal text-gray-400">
						{{ $t('global.postedOn') }}
					</span>
					<span class="text-sm md:text-md font-semibold">
						{{ reviewSources[review.source].name }}
					</span>
				</div>
			</div>
		</a>
	</div>
</template>

<script>
import StarIcon from '../icons/StarIcon.vue';
import { reviewSources } from '../../assets/config/guestReviews';

export default {
	name: 'GuestReview',
	components: {
		StarIcon,
	},
	props: {
		review: {
			type: Object,
			default: () => ({}),
		},
	},
	setup() {
		return {
			reviewSources,
		};
	},
	methods: {
		getRandomPastelColor() {
			const pastelColors = [
				'bg-pastel-red',
				'bg-pastel-orange',
				'bg-pastel-green',
				'bg-pastel-blue',
				'bg-pastel-purple',
				'bg-pastel-pink',
				'bg-pastel-teal',
			];

			const randomIndex = Math.floor(Math.random() * pastelColors.length);
			return pastelColors[randomIndex];
		},
	},
};
</script>