<template>
	<div 
		class="bg-transparent w-full text-center py-2 absolute bottom-0 left-0 z-50 flex flex-col justify-center items-center"
		:class="textColor"
	>
		<div class="flex flex-col justify-center items-center h-fit">
			<span class="mb-[-0.5rem]">&copy; 2024</span>
			<a href="/privacy-policy" class="h-fit">
				<span class="text-xs">
					Privacy Policy
				</span>
			</a>
		</div>

		<a href="https://studio31.hr" target="_blank" class="absolute bottom-4 right-4">
			<img
				src="/images/icons/logo-v00.png"
				alt="Studio 31 Logo"
				class="h-auto w-12"
			/>
		</a>
	</div>
</template>

<script>
export default {
	name: 'FooterComponent',
	props: {
		textColor: {
			type: String,
			default: 'text-white',
		},
	},
};
</script>