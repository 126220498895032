export const images = [
	'images/house/1.jpg',
	'images/house/2.jpg',
	'images/house/4.jpg',
	'images/house/5.jpg',
	'images/house/6.jpg',
	'images/house/7.jpg',
	'images/house/8.jpg',
	'images/house/9.jpg',
	'images/house/10.jpg',
	'images/house/11.jpg',
	'images/house/12.jpg',
	'images/house/13.jpg',
	'images/house/14.jpg',
	'images/house/15.jpg',
	'images/house/16.jpg',
	'images/house/17.jpg',
	'images/house/18.jpg',
	'images/house/19.jpg',
	'images/house/20.jpg',
	'images/house/21.jpg',
	'images/house/22.jpg',
	'images/house/23.jpg',
	'images/house/24.jpg',
	'images/house/25.jpg',
	'images/house/26.jpg',
	'images/house/27.jpg',
	'images/house/28.jpg',
	'images/house/29.jpg',
];

export const galleryPreview = [
	'/images/pool-1.jpg',
	'/images/grill-1.png',
	'/images/front-1.png',
	'/images/front-2.png',
	'images/house/4.jpg',
	'/images/down-2.png',
];