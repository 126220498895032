<template>
	<div class="w-full lg:max-w-lg">
		<label class="block text-md lg:text-lg">
			{{ label }}
		</label>
		
		<div class="flex flex-col md:flex-row justify-start items-start md:items-center gap-4">
			<DateInput
				:min="minFrom"
				:modelValue="valueFrom"
				@update:model="$emit('update:from', $event)"
			/>

			<span class="hidden md:block">-</span>

			<DateInput
				:min="valueFrom || minFrom"
				:modelValue="valueTo"
				@update:model="$emit('update:to', $event)"
			/>
		</div>
	</div>
</template>

<script>
import DateInput from './DateInput.vue';

export default {
	name: 'DateFromTo',
	components: {
		DateInput,
	},
	props: {
		label: {
			type: String,
			default: 'Label',
		},
		valueFrom: {
			type: String,
			default: '',
		},
		valueTo: {
			type: String,
			default: '',
		},
		minFrom: {
			type: String,
			default: '',
		},
	},
	emits: [
		'update:from',
		'update:to',
	],
};
</script>