<template>
	<svg :fill="color" :width="width" :height="height" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" xml:space="preserve">
		<g> 
			<path d="M341.333,213.333h-320C8.533,213.333,0,221.867,0,234.667V320c0,12.8,8.533,21.333,21.333,21.333h320 c12.8,0,21.333-8.533,21.333-21.333v-85.333C362.667,221.867,354.133,213.333,341.333,213.333z M320,298.667H42.667V256H320 V298.667z"></path> 
			<path d="M490.667,213.333H341.333c-12.8,0-21.333,8.533-21.333,21.333V320c0,12.8,8.533,21.333,21.333,21.333h149.333 c12.8,0,21.333-8.533,21.333-21.333v-85.333C512,221.867,503.467,213.333,490.667,213.333z M469.333,298.667H362.667V256h106.667 V298.667z"></path> 
			<path d="M130.133,23.467c-10.667-4.267-23.467,0-27.733,10.667c-4.267,10.667,0,23.467,10.667,27.733 c4.267,2.133,6.4,4.267,6.4,8.533s-4.267,8.533-8.533,8.533C89.6,78.933,83.2,104.533,96,115.2H66.133 c-25.6,2.133-44.8,25.6-44.8,55.467c0,12.8,8.533,21.333,21.333,21.333C55.467,192,64,183.467,64,170.667 C64,162.133,66.133,160,66.133,160H102.4c21.333,0,40.533-17.067,40.533-40.533c0-2.133,0-6.4,0-8.533 c10.667-8.533,19.2-23.467,19.2-40.533C162.133,49.067,149.333,32,130.133,23.467z"></path> 
			<path d="M505.6,6.4c-8.533-8.533-21.333-8.533-29.867,0L6.4,475.733c-8.533,8.533-8.533,21.333,0,29.867 c8.533,8.533,21.333,8.533,29.867,0L505.6,36.267C514.133,27.733,514.133,14.933,505.6,6.4z"></path> 
		</g>
	</svg>
</template>

<script>
export default {
	name: 'NoSmokingIcon',
	props: {
		width: {
			type: Number,
			default: 30,
		},
		height: {
			type: Number,
			default: 41,
		},
		color: {
			type: String,
			default: 'white',
		},
	},
}
</script>