<template>
	<div class="w-fit">
		<label v-if="label" class="block text-md lg:text-lg">
			{{ label }}
		</label>
		<input
			:value="modelValue"
			:min="min"
			@input="$emit('update:model', $event.target.value)"
			type="date"
			class="block w-fit bg-brown-2 rounded-md py-1 px-2 h-9 lg:h-11"
		/>
	</div>
</template>

<script>
export default {
	name: 'DateInput',
	props: {
		label: {
			type: String,
			default: '',
		},
		modelValue: {
			type: String,
			default: '',
		},
		min: {
			type: String,
			default: '',
		},
	},
	emits: [
		'update:model'
	],
};
</script>