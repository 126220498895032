import { createRouter, createWebHistory } from 'vue-router';
import Home from './views/Home.vue';
import PrivacyPolicyView from './views/PrivacyPolicyView.vue';

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,
	},
	{
		path: '/privacy-policy',
		name: 'Privacy Policy',
		component: PrivacyPolicyView,
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

router.beforeEach((to, from, next) => {
	if (to.matched.length === 0) {
		next('/');
	} else {
		next();
	}
});

export default router;