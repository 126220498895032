export const infoConfig = {
	name: "House Ivana",
	guests: 4,
	extraGuests: 1,
	stars: 3,
	coords: [45.17876901257071, 14.123838677911637],
	googleMapsLink: 'https://maps.app.goo.gl/L7fEgGHs17rgpc5k9',
	address: 'Veljaki 41, 52232, Kršan, Croatia',
	wappLink: 'https://wa.me/385914863186',
	phone: '+385 91 4863 186', // when changing phone, remember to change wapp link
	email: 'irenabasic76@gmail.com',
	facebook: 'https://www.facebook.com/houseivana1',
	authKey: 'f38ea5c9-8461-4745-89cc-927dfb6f4bea',
};

import { markRaw } from "vue";
import ParkingIcon from "@/components/icons/ParkingIcon.vue";
import PawIcon from "@/components/icons/PawIcon.vue";
import PoolIcon from "@/components/icons/PoolIcon.vue";
import HomeIcon from "@/components/icons/HomeIcon.vue";
import WifiIcon from "@/components/icons/WifiIcon.vue";
import NoSmokingIcon from "@/components/icons/NoSmokingIcon.vue";
import GrillIcon from "@/components/icons/GrillIcon.vue";

export const services = [
	{
		name: 'services.squareMeters',
		icon: markRaw(HomeIcon),
	},
	{
		name: 'services.freeParking',
		icon: markRaw(ParkingIcon),
	},
	{
		name: 'services.freeWifi',
		icon: markRaw(WifiIcon),
	},
	{
		name: 'services.petFriendly',
		icon: markRaw(PawIcon),
	},
	{
		name: 'services.grill',
		icon: markRaw(GrillIcon),
	},
	{
		name: 'services.noSmoking',
		icon: markRaw(NoSmokingIcon),
	},
	{
		name: 'services.privateOutdoorPool',
		icon: markRaw(PoolIcon),
	},
];