<template>
	<div class="relative w-full py-10 md:px-10 bg-blue-3">
		<VerticalLines />

		<div class="relative w-full lg:w-3/4 flex flex-col justify-center items-start gap-4">
			<span class="text-xl md:text-2xl lg:text-3xl font-semibold font-display px-10 md:px-0">
				{{ $t('global.locationTitle') }}
			</span>

			<div class="w-full grid grid-rows-2 md:grid-rows-1 md:grid-cols-2">
				<div class="grid place-content-center p-8">
					<div class="max-w-full w-72 md:w-80 lg:w-[22rem] h-80 z-0">
						<LMap 
							ref="map" 
							:zoom="8" 
							:min-zoom="6"
							:max-zoom="16"
							:max-bounds="[infoConfig.coords, infoConfig.coords]"
							:center="infoConfig.coords"
						>
							<LTileLayer
								url="https://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}"
								layer-type="base"
								name="GoogleRoadMap"
							></LTileLayer>
							<LMarker :lat-lng="infoConfig.coords">
								<LIcon :icon-anchor="[15, 30]">
									<a :href="infoConfig.googleMapsLink" target="_blank">
										<PinIcon :width="30" :height="30" color="black" />
									</a>
								</LIcon>
							</LMarker>
						</LMap>
					</div>
				</div>

				<div class="h-full flex flex-col justify-start items-start gap-6 py-8 px-10 md:px-0">
					<a :href="infoConfig.googleMapsLink" target="_blank" class="flex justify-start items-center gap-3">
						<PinIcon :width="30" :height="30" color="black" />
						<span class="font-display text-lg md:text-xl lg:text-2xl font-normal">
							{{ infoConfig.address }}
						</span>
					</a>
					<a :href="infoConfig.wappLink" target="_blank" class="flex justify-start items-center gap-3">
						<WhatsappIcon :width="30" :height="30" color="black" />
						<span class="font-display text-lg md:text-xl lg:text-2xl font-normal">
							{{ infoConfig.phone}}
						</span>
					</a>
					<a :href="`mailto:${infoConfig.email}`" target="_blank" class="flex justify-start items-center gap-3">
						<EmailIcon :width="30" :height="30" color="black" />
						<span class="font-display text-lg md:text-xl lg:text-2xl font-normal">
							{{ infoConfig.email }}
						</span>
					</a>
					<a :href="infoConfig.facebook" target="_blank" class="flex justify-start items-center gap-3">
						<FacebookIcon :width="30" :height="30" color="black" />
						<span class="font-display text-lg md:text-xl lg:text-2xl font-normal">
							{{ infoConfig.name}}
						</span>
					</a>
				</div>
			</div>
		</div>

		<Footer text-color="text-black" />
	</div>
</template>

<script>
import PinIcon from './icons/PinIcon.vue';
import WhatsappIcon from './icons/WhatsappIcon.vue';
import EmailIcon from './icons/EmailIcon.vue';
import FacebookIcon from './icons/FacebookIcon.vue';
import VerticalLines from './graphics/VerticalLines.vue';

import { infoConfig } from '../assets/config/infoConfig';

import "leaflet/dist/leaflet.css";
import { LMap, LTileLayer, LMarker, LIcon } from "@vue-leaflet/vue-leaflet";

import Footer from './graphics/Footer.vue';

export default {
	name: 'InfoPage',
	components: {
		VerticalLines,
		PinIcon,
		WhatsappIcon,
		FacebookIcon,
		EmailIcon,
		LMap,
		LTileLayer,
		LMarker,
		LIcon,
		Footer,
	},
	setup() {
		return {
			infoConfig,
		};
	},
	data() {
		return {
			zoom: 8,
		};
	},
};
</script>