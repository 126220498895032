<template>
	<div class="relative w-full py-10 px-10">
		<VerticalLines />
		
		<div class="relative w-full lg:w-3/4 flex flex-col justify-center items-start gap-4 z-20">
			<span class="text-xl md:text-2xl lg:text-3xl font-display font-semibold text-blue-1">
				{{ $t('aboutPage.title') }}
			</span>

			<div class="w-full flex flex-wrap gap-2">
				<div
					v-for="service in services"
					:key="service"
					class="flex w-fit justify-start items-center gap-2 rounded p-1 md:p-2 pr-3 md:pr-4 border border-blue-200"
				>
					<component :is="service.icon" :width="20" :height="20" color="black" class="scale-[0.8] md:scale-[1]"/>
					<span class="text-xs md:text-sm font-normal text-black">
						{{ $t(service.name) }}
					</span>
				</div>
			</div>

			<p class="text-md md:text-lg">
				{{ $t('aboutPage.aboutText') }}
			</p>

			<div class="flex flex-col justify-center items-start gap-2 mt-4">
				<span class="text-lg md:text-xl capitalize italic font-bold text-blue-1">
					{{ $t('aboutPage.house') }}
				</span>

				<p class="text-md md:text-lg">
					{{ $t('aboutPage.houseTwoLevels') }}:

					<ul class="list-disc list-inside text-md md:text-lg">
						<li>
							<span class="font-bold mr-1">{{ $t('aboutPage.groundFloor') }}:</span>
							{{ $t('aboutPage.groundFloorText') }}
						</li>
						<li>
							<span class="font-bold mr-1">{{ $t('aboutPage.firstFloor') }}:</span>
							{{ $t('aboutPage.firstFloorText') }}
						</li>
					</ul>
				</p>
			</div>

			<div class="flex flex-col justify-center items-start gap-2 mt-4">
				<span class="text-lg md:text-xl capitalize italic font-bold text-blue-1">
					{{ $t('aboutPage.garden') }}
				</span>

				<p class="text-md md:text-lg">
					{{ $t('aboutPage.gardenText') }}

					<ul class="list-disc list-inside text-md md:text-lg">
						<li>
							{{ $t('aboutPage.bbqArea') }}
						</li>
						<li>
							{{ $t('aboutPage.swimmingPool') }}
						</li>
						<li>
							{{ $t('aboutPage.deckChairs') }}
						</li>
						<li>
							{{ $t('aboutPage.swings') }}
						</li>
					</ul>
				</p>
			</div>

			
			<div class="flex flex-col justify-center items-start gap-2 mt-4">
				<span class="text-lg md:text-xl capitalize italic font-bold text-blue-1">
					{{ $t('aboutPage.aroundHouse') }}
				</span>

				<p class="text-md md:text-lg">
					{{ $t('aboutPage.aroundHouseText1') }}
				</p>

				<p class="text-md md:text-lg">
					{{ $t('aboutPage.aroundHouseText2') }}
				</p>
			</div>

			<p class="text-md md:text-lg mt-4">
				{{ $t('aboutPage.closingText') }}
			</p>

			<div class="flex justify-start items-center gap-2 mt-4">
				<a 
					href="https://www.istria-krsan.com/en/hr" 
					target="_blank"
					class="text-sm md:text-lg text-center text-brown-3 hover:text-brown-1 border-r pr-2 border-blue-1"
				>
					<span>{{ $t('discover.krsan') }}</span>
				</a>
				<a 
					href="https://rabac-labin.com/en/"
					target="_blank" 
					class="text-sm md:text-lg text-center text-brown-3 hover:text-brown-1 border-r pr-2 border-blue-1"
				>
					<span>{{ $t('discover.labin') }}</span>
				</a>
				<a 
					href="https://www.istra.hr/en"
					target="_blank" 
					class="text-sm md:text-lg text-center text-brown-3 hover:text-brown-1"
				>
					<span>{{ $t('discover.istria') }}</span>
				</a>
			</div>
		</div>
	</div>
</template>

<script>
import { services } from '@/assets/config/infoConfig';
import StarIcon from './icons/StarIcon.vue';
import VerticalLines from './graphics/VerticalLines.vue';

export default {
	name: 'AboutPage',
	components: {
		VerticalLines,
		StarIcon,
	},
	setup() {
		return {
			services,
		};
	},
};
</script>