export default {
	hr: {
		global: {
			bookNow: 'Rezerviraj',
			gallery: 'Galerija slika',
			locationTitle: 'Lokacija i kontakt informacije',
			postedOn: 'Objavljeno na',
		},
		aboutPage: {
			title: 'Šarmantna kuća za odmor u Istri',
			aboutText: "Dobrodošli u našu ugodnu ljetnu kuću, smještenu u velikom vrtu samo 2 km od malog sela Kršan u prekrasnom istarskom zaleđu. Okružen mediteranskim biljem, ovaj mirni kutak udaljen je samo 5 km od mirnog primorskog sela Plomin Luke i 15 km od Rabača, poznatog turističkog područja. Uživajte u mirnom okruženju savršenom za opuštajući odmor, s pogledom na najvišu istarsku planinu - Učku.",
			house: "Kuća",
			houseTwoLevels: "Kuća ima dva kata",
			groundFloor: "Prizemlje",
			firstFloor: "Prvi kat",
			groundFloorText: "Udoban dnevni boravak s kaučem na razvlačenje, kuhinja i blagovaonica te kupaonica.",
			firstFloorText: "Dvije spavaće sobe - jedna s bračnim krevetom i druga s dva odvojena kreveta. Dekor je miks starog i novog stila.",
			garden: "Vrt",
			gardenText: "Prostrani ograđeni vrt idealan je za obitelji s djecom. Uključuje:",
			bbqArea: "Prostor za roštiljanje",
			swimmingPool: "Bazen",
			deckChairs: "Ležaljke za sunčanje",
			swings: "Ljuljačke za djecu",
			aroundHouse: "Oko kuće",
			aroundHouseText1: "Kuća se nalazi u brdovitom području okruženom vinogradima i maslinicima. Poznata je po odličnoj hrani i tradiciji. Sve je blizu, od mirnih uvala do dobro opremljenih plaža u ljetovalištima. Područje je savršeno za biciklizam i trčanje.",
			aroundHouseText2: "Možete istražiti obližnja sela, turističke gradove i velike gradove poput Pule i Rijeke. Posjetite Brijunske otoke i otok Cres te uživajte u lokalnim festivalima i događanjima.",
			closingText: "Provedite opuštajući i zabavan odmor u ovom prekrasnom prirodnom okruženju.",
		},
		discover: {
			krsan: "Otkrijte Kršan",
			labin: "Otkrijte Labin & Rabac",
			istria: "Otkrijte Istru",
		},
		request: {
			title: "Pošaljite upit za rezervaciju",
			name: "Ime",
			email: "Email",
			guests: "Broj gostiju",
			checkInOut: "Dolazak/Odlazak",
			message: "Poruka (opcionalno)",
			submit: "Pošalji",
			sending: "Slanje...",
			alreadyVisited: "Jeste li nas već posjetili?",
			leaveReview: "Ostavite recenziju.",
			submitted: "Hvala! Vaš upit je poslan.",
			error: "Došlo je do pogreške. Molimo pokušajte ponovno ili nas kontaktirajte izravno putem e-maila ili WhatsApp-a.",
			privacyPolicy: "Slanjem ovog obrasca pristajete na našu Politiku privatnosti.",
		},
		services: {
			wholeApartment: 'Cijeli apartman',
			squareMeters: '90 kvadratnih metara',
			freeParking: 'Besplatan parking',
			freeWifi: 'Besplatan WiFi',
			petFriendly: 'Domaći ljubimci dozvoljeni',
			familyRooms: 'Obiteljske sobe',
			grill: 'Roštilj',
			noSmoking: 'Zabranjeno pušenje',
			privateOutdoorPool: 'Privatni vanjski bazen',
		},
		reviews: {
			jenny: 'Imali smo sjajan boravak u smještaju. Posebno nam se svidio privatni vrt s bazenom. To je predivno mjesto za isključiti se i uživati u miru i tišini. Kuća i domaćini također su bili sjajni.',
			andreas: 'Udobna kuća, brz pristup nekoliko različitih plaža. Privatni vrt sa svime što vam je potrebno. Drago nam je što smo ovdje proveli tjedan dana u lijepoj kući.',
			cassandra: 'Imali smo sjajno vrijeme i stvarno uživali u boravku u Ireninom mjestu. Predivna kuća i vrt. Svidjelo nam se što smo imali privatni bazen i mogućnost istraživanja područja automobilom do najbližih plaža i planinarskih mjesta. Kuća je blizu ceste pa je tijekom dana uvijek bilo malo buke, ali ništa prestrašno. Savršeno za putovanje s našim psom. Irena je sjajan domaćin, dala nam je lokalne preporuke i uvijek je bila dostupna. Hvala što ste nas primili! Rado bismo se vratili!!',
			family: 'Ako tražite ugodnu kuću u Istri, prestanite tražiti! House Ivana nudi vam sve što vam je potrebno za sjajan odmor. Irena i Haris najtopliji su domaćini koje smo ikad upoznali. Učine sve da se osjećate ugodno. Ako smo imali neka pitanja, reagirali su vrlo brzo i došli za nekoliko minuta. Supermarketi i plaže (posjetite Ravni) su u blizini. Labin je lijep grad s dobrim restoranima. Proveli smo predivno vrijeme u ovoj šarmantnoj kući na savršenoj lokaciji.',
			sarah: 'To je stvarno lijepa kuća s velikim vrtom! Imate bazen i trampolin samo za sebe! To je sjajno! Kuća je u redu, 2 spavaće sobe na katu i dolje kuhinja s dnevnim boravkom i kupaonicom. U selo se dolazi samo automobilom. U Kršanu postoji vrlo dobar restoran (Stancija Stare Staze), malo je skuplji (beefsteak 20 €) ali vrijedi!! Ogroman vrt za sjedenje s prekrasnim pogledom! Irena i Haris su vrlo slatki par i super ljubazni domaćini!! Preporučujem ovo mjesto, posebno za obitelji!',
		},
	},
	gb: {
		global: {
			bookNow: 'Book now',
			gallery: 'Photo gallery',
			locationTitle: 'Location & Contact information',
			postedOn: 'Posted on',
		},
		aboutPage: {
			title: 'Charming Summer House in Istria',
			aboutText: "Welcome to our cozy summer house, set in a large garden just 2km from a small village Kršan in the beautiful Istrian countryside. Surrounded by Mediterranean plants, this peaceful spot is only 5km from a quiet seaside village Plomin Luka and 15km from Rabac, a well-known tourist area. Enjoy a calm setting perfect for a relaxing holiday, with a view of Istria's highest mountain - Učka.",
			house: "The house",
			houseTwoLevels: "The house has two levels",
			groundFloor: "Ground floor",
			firstFloor: "First floor",
			groundFloorText: "A comfortable living room with a sofa bed, a kitchen and dining area, and a bathroom.",
			firstFloorText: "Two bedrooms—one with a double bed and one with two single beds. The decor is a mix of old and new styles.",
			garden: "The garden",
			gardenText: "The spacious fenced garden is great for families with kids. It includes:",
			bbqArea: "A barbecue area",
			swimmingPool: "A swimming pool",
			deckChairs: "Deck chairs for sunbathing",
			swings: "Swings for the children",
			aroundHouse: "Around the house",
			aroundHouseText1: "The house is in a hilly area surrounded by vineyards and olive groves. It's known for its great food and traditions. Everything is close by, from quiet little bays to well-equipped beaches at the resorts. The area is perfect for biking and jogging.",
			aroundHouseText2: "You can explore nearby villages, tourist towns, and big cities like Pula and Rijeka. Visit the Brijuni Islands and the island of Cres, and enjoy local festivals and events.",
			closingText: "Have a relaxing and fun holiday in this beautiful natural setting.",
		},
		discover: {
			krsan: "Discover Kršan",
			labin: "Discover Labin & Rabac",
			istria: "Discover Istria",
		},
		request: {
			title: "Send a request for booking",
			name: "Name",
			email: "Email",
			guests: "Number of guests",
			checkInOut: "Check-in/out",
			message: "Message (optional)",
			submit: "Submit",
			sending: "Sending...",
			alreadyVisited: "Have you already visited us?",
			leaveReview: "Leave a review.",
			submitted: "Thank you! Your request has been submitted.",
			error: "An error occurred. Please try again or contact us directly via email or WhatsApp.",
			privacyPolicy: "By submitting this form, you agree to our Privacy Policy.",
		},
		services: {
			wholeApartment: 'Whole apartment',
			squareMeters: '90 square meters',
			freeParking: 'Free parking',
			freeWifi: 'Free WiFi',
			petFriendly: 'Pet friendly',
			familyRooms: 'Family rooms',
			grill: 'Grill',
			noSmoking: 'No smoking',
			privateOutdoorPool: 'Private outdoor pool',
		},
		reviews: {
			jenny: 'We had a great stay at the property. We particularly liked the private garden with the pool. It was a wonderful place to switch off and enjoy the peace and quiet. The house and the hosts were also great.',
			andreas: 'Cozy house, quick access to several different beaches. Private back with everything you need. We are happy that we stayed here for a week in the nice house.',
			cassandra: 'We\'ve had a great time and really enjoyed staying at Irenas place. Beautiful house and garden. We loved having a private pool and exploring the area by car to the nearest beaches and hiking spots. The house is close to a road so during the day there was always a bit of noise but nothing too bad. Perfekt to travel with our dog as well. Irena is a great host, gave us local recommendations and was always available. Thank you for having us! We would definitely return!!',
			family: 'If you are looking for a pleasant house in Istria stop searching! House Ivana offers you everthing you need for a splendid holiday. Irena and Haris are the most warm hosts we ever met. They do everything to feel you comfortable. If we had some questions they reacted very prompt and came by in a few minutes. Supermarkets and beaches (try Ravni) are nearby. Labin is a nice city with good restaurants. We had a wonderfull time in this charming house on a perfect location.',
			sarah: 'It\'s really nice house with a big garden! You ve a pool and a trampoline for your own! That\'s great! The house is ok, 2 bedrooms upstairs and downstairs a kitchen with living room and bath. The village is just reachable with a car. There is a very good Restaurant in Kršan (Stancija Stare Staze), it\'s a bit more expensive (beefsteak 20€) but worth it!! Huge garden to sit with a stunning view! Irena and Haris are a very sweet couple and super nice hosts!! I recommend this place, especially for families!',
		},
	},
	de: {
		global: {
			bookNow: 'Jetzt buchen',
			gallery: 'Fotogalerie',
			locationTitle: 'Standort & Kontaktinformationen',
			postedOn: 'Veröffentlicht auf',
		},
		aboutPage: {
			title: 'Charmantes Sommerhaus in Istrien',
			aboutText: "Willkommen in unserem gemütlichen Sommerhaus, das in einem großen Garten nur 2 km von einem kleinen Dorf Kršan in der schönen istrischen Landschaft entfernt liegt. Umgeben von mediterranen Pflanzen ist dieser friedliche Ort nur 5 km von einem ruhigen Küstendorf Plomin Luka und 15 km von Rabac, einem bekannten Touristenort entfernt. Genießen Sie eine ruhige Umgebung, die perfekt für einen erholsamen Urlaub ist, mit Blick auf den höchsten Berg Istriens - Učka.",
			house: "Das Haus",
			houseTwoLevels: "Das Haus hat zwei Etagen",
			groundFloor: "Erdgeschoss",
			firstFloor: "Erster Stock",
			groundFloorText: "Ein gemütliches Wohnzimmer mit Schlafsofa, eine Küche und ein Essbereich sowie ein Badezimmer.",
			firstFloorText: "Zwei Schlafzimmer - eines mit einem Doppelbett und eines mit zwei Einzelbetten. Die Einrichtung ist eine Mischung aus alten und neuen Stilen.",
			garden: "Der Garten",
			gardenText: "Der geräumige eingezäunte Garten ist ideal für Familien mit Kindern. Er umfasst:",
			bbqArea: "Ein Grillplatz",
			swimmingPool: "Ein Schwimmbad",
			deckChairs: "Liegestühle zum Sonnenbaden",
			swings: "Schaukeln für die Kinder",
			aroundHouse: "Um das Haus herum",
			aroundHouseText1: "Das Haus liegt in einer hügeligen Gegend, umgeben von Weinbergen und Olivenhainen. Es ist bekannt für sein gutes Essen und seine Traditionen. Alles ist in der Nähe, von ruhigen kleinen Buchten bis zu gut ausgestatteten Stränden in den Resorts. Die Gegend ist perfekt zum Radfahren und Joggen.",
			aroundHouseText2: "Sie können nahegelegene Dörfer, Touristenstädte und große Städte wie Pula und Rijeka erkunden. Besuchen Sie die Brijuni-Inseln und die Insel Cres und genießen Sie lokale Feste und Veranstaltungen.",
			closingText: "Verbringen Sie einen entspannten und unterhaltsamen Urlaub in dieser schönen natürlichen Umgebung.",
		},
		discover: {
			krsan: "Entdecken Sie Kršan",
			labin: "Entdecken Sie Labin & Rabac",
			istria: "Entdecken Sie Istrien",
		},
		request: {
			title: "Senden Sie eine Buchungsanfrage",
			name: "Name",
			email: "Email",
			guests: "Anzahl der Gäste",
			checkInOut: "Check-in/out",
			message: "Nachricht (optional)",
			submit: "Absenden",
			sending: "Senden...",
			alreadyVisited: "Haben Sie uns bereits besucht?",
			leaveReview: "Hinterlassen Sie eine Bewertung.",
			submitted: "Vielen Dank! Ihre Anfrage wurde gesendet.",
			error: "Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut oder kontaktieren Sie uns direkt per E-Mail oder WhatsApp.",
			privacyPolicy: "Durch Absenden dieses Formulars stimmen Sie unserer Datenschutzrichtlinie zu.",
		},
		services: {
			wholeApartment: 'Ganze Wohnung',
			squareMeters: '90 Quadratmeter',
			freeParking: 'Kostenlose Parkplätze',
			freeWifi: 'Kostenloses WLAN',
			petFriendly: 'Haustiere erlaubt',
			familyRooms: 'Familienzimmer',
			grill: 'Grill',
			noSmoking: 'Rauchen verboten',
			privateOutdoorPool: 'Privater Außenpool',
		},
		reviews: {
			jenny: 'Wir hatten einen tollen Aufenthalt in der Unterkunft. Besonders der privaten Garten mit dem Pool hat uns gefallen. Dort konnte man wunderbar abschalten und die Ruhe genießen. Auch das Haus und die Gastgeber waren klasse.',
			andreas: 'Gemütliches Haus, schneller Zugang zu mehreren verschiedenen Stränden. Privater Rücken mit allem, was Sie brauchen. Wir sind froh, dass wir eine Woche hier in dem schönen Haus verbracht haben.',
			cassandra: 'Wir hatten eine tolle Zeit und haben den Aufenthalt bei Irenas wirklich genossen. Schönes Haus und Garten. Wir liebten es, einen privaten Pool zu haben und die Gegend mit dem Auto zu den nächsten Stränden und Wandergebieten zu erkunden. Das Haus liegt in der Nähe einer Straße, so dass es tagsüber immer etwas Lärm gab, aber nichts Schlimmes. Perfekt auch für Reisen mit unserem Hund. Irena ist eine tolle Gastgeberin, hat uns lokale Empfehlungen gegeben und war immer verfügbar. Vielen Dank, dass Sie uns haben! Wir würden auf jeden Fall wiederkommen!!',
			family: 'Wenn Sie auf der Suche nach einem schönen Haus in Istrien sind, hören Sie auf zu suchen! House Ivana bietet Ihnen alles, was Sie für einen herrlichen Urlaub brauchen. Irena und Haris sind die herzlichsten Gastgeber, die wir je getroffen haben. Sie tun alles, damit Sie sich wohlfühlen. Wenn wir Fragen hatten, reagierten sie sehr schnell und kamen innerhalb weniger Minuten vorbei. Supermärkte und Strände (versuchen Sie Ravni) sind in der Nähe. Labin ist eine schöne Stadt mit guten Restaurants. Wir hatten eine wundervolle Zeit in diesem charmanten Haus in perfekter Lage.',
			sarah: 'Es ist wirklich ein schönes Haus mit einem großen Garten! Sie haben einen Pool und ein Trampolin für sich allein! Das ist großartig! Das Haus ist ok, 2 Schlafzimmer oben und unten eine Küche mit Wohnzimmer und Bad. Das Dorf ist nur mit dem Auto erreichbar. Es gibt ein sehr gutes Restaurant in Kršan (Stancija Stare Staze), es ist etwas teurer (Beefsteak 20 €), aber es lohnt sich!! Riesiger Garten zum Sitzen mit atemberaubender Aussicht! Irena und Haris sind ein sehr süßes Paar und supernette Gastgeber!! Ich empfehle diesen Ort, besonders für Familien!',
		},
	},
};