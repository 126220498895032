<template>
	<svg 
		:fill="color" :height="height" :width="width" 
		xmlns="http://www.w3.org/2000/svg" 
		viewBox="0 0 297 297" xml:space="preserve"
	>
		<g>
			<path d="M10.034,219.489c19.003,0,29.05-3.494,37.914-6.577c8.429-2.932,15.707-5.464,31.321-5.464s22.893,2.532,31.321,5.464
				c8.864,3.083,18.91,6.577,37.914,6.577s29.05-3.494,37.913-6.577c8.428-2.932,15.707-5.464,31.319-5.464
				c15.613,0,22.892,2.532,31.319,5.464c8.863,3.083,18.908,6.577,37.911,6.577c5.541,0,10.034-4.493,10.034-10.034
				c0-5.541-4.493-10.034-10.034-10.034c-15.612,0-22.891-2.532-31.318-5.464c-8.863-3.083-18.908-6.577-37.912-6.577
				c-3.462,0-6.62,0.119-9.535,0.327V89.176c0-13.625,11.084-24.709,24.709-24.709c13.624,0,24.708,11.084,24.708,24.709
				c0,5.541,4.493,10.034,10.034,10.034s10.034-4.493,10.034-10.034c0-24.69-20.086-44.777-44.776-44.777
				s-44.777,20.087-44.777,44.777v38.002h-79.267V89.176c0-13.625,11.084-24.709,24.709-24.709c13.624,0,24.708,11.084,24.708,24.709
				c0,5.541,4.493,10.034,10.034,10.034s10.034-4.493,10.034-10.034c0-24.69-20.086-44.777-44.776-44.777S88.799,64.487,88.799,89.176
				v98.531c-2.913-0.208-6.07-0.327-9.53-0.327c-19.004,0-29.05,3.494-37.914,6.577c-8.429,2.932-15.707,5.464-31.321,5.464
				C4.493,199.421,0,203.914,0,209.455C0,214.996,4.493,219.489,10.034,219.489z M188.133,147.246v13.044h-79.267v-13.044H188.133z
				M108.867,180.357h79.267v10.882c-3,0.876-5.71,1.814-8.309,2.719c-8.428,2.932-15.707,5.464-31.32,5.464
				s-22.893-2.532-31.321-5.464c-2.602-0.905-5.314-1.843-8.316-2.72V180.357z"/>
			<path d="M286.966,232.533c-15.612,0-22.891-2.532-31.318-5.464c-8.863-3.083-18.908-6.577-37.912-6.577
				c-19.002,0-29.049,3.494-37.912,6.577c-8.428,2.932-15.707,5.464-31.32,5.464s-22.893-2.532-31.321-5.464
				c-8.864-3.083-18.91-6.577-37.914-6.577c-19.004,0-29.05,3.494-37.914,6.577c-8.429,2.932-15.707,5.464-31.321,5.464
				C4.493,232.533,0,237.026,0,242.567c0,5.541,4.493,10.034,10.034,10.034c19.003,0,29.05-3.494,37.914-6.577
				c8.429-2.932,15.707-5.464,31.321-5.464s22.893,2.532,31.321,5.464c8.864,3.083,18.91,6.577,37.914,6.577s29.05-3.494,37.913-6.577
				c8.428-2.932,15.707-5.464,31.319-5.464c15.613,0,22.892,2.532,31.319,5.464c8.863,3.083,18.908,6.577,37.911,6.577
				c5.541,0,10.034-4.493,10.034-10.034C297,237.026,292.507,232.533,286.966,232.533z"/>
		</g>
	</svg>
</template>

<script>
export default {
	name: 'PoolIcon',
	props: {
		width: {
			type: Number,
			default: 50,
		},
		height: {
			type: Number,
			default: 50,
		},
		color: {
			type: String,
			default: 'white',
		},
	},
}
</script>