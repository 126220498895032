export const links = {
	'airbnb': 'https://hr.airbnb.com/rooms/1817711/reviews?_set_bev_on_new_domain=1723282052_EANWUxOGJhNGJlMz&source_impression_id=p3_1723282053_P3-vDasothXnS1YN',
	'booking': 'https://www.booking.com/hotel/hr/guest-house-ivana-veljaki1.hr.html?aid=318615&label=New_English_EN_HR_27034798465-KbhXF8k7R6bhUiQ1j4t8YgS637942139981%3Apl%3Ata%3Ap1%3Ap2%3Aac%3Aap%3Aneg%3Afi%3Atidsa-140566009465%3Alp1028857%3Ali%3Adec%3Adm%3Aag27034798465%3Acmp400849465&sid=97d0b9eec23d2de37f36e9046893580b&dest_id=-85986;dest_type=city;dist=0;group_adults=2;group_children=0;hapos=1;hpos=1;no_rooms=1;req_adults=2;req_children=0;room1=A%2CA;sb_price_type=total;sr_order=popularity;srepoch=1723282240;srpvid=7f6a42de93db00db;type=total;ucfs=1&#tab-reviews',
	'google': 'https://www.google.com/search?hl=hr-HR&sca_esv=e52debdf81d4b9f2&sca_upv=1&cs=1&output=search&tbm=lcl&q=House+Ivana&ludocid=14528086903863318716&lsig=AB86z5W2JoBvokwmPwXdSncLWWqT&shndl=30&shem=vslcca&source=sh/x/kp/local/m1/1&kgs=d1e64d36f9a8aa55#lkt=LocalPoiReviews&rlfi=hd:;si:14528086903863318716,l,CgtIb3VzZSBJdmFuYZIBE3ZhY2F0aW9uX2FwcGFydG1lbnQ;mv:[[45.178748577319034,14.124120823347514],[45.17838862268097,14.123610176652488]]',
};

export const reviewSources = {
	'airbnb': {
		name: 'Airbnb',
		link: links.airbnb,
		icon: '/images/icons/airbnb_icon.png',
	},
	'booking': {
		name: 'Booking.com',
		link: links.booking,
		icon: '/images/icons/booking_icon.png',
	},
	'google': {
		name: 'Google',
		link: links.google,
		icon: '/images/icons/google_icon.png',
		reviewLink: 'https://www.google.com/search?hl=hr-HR&sca_esv=e52debdf81d4b9f2&sca_upv=1&cs=1&output=search&tbm=lcl&q=House+Ivana&ludocid=14528086903863318716&lsig=AB86z5W2JoBvokwmPwXdSncLWWqT&shndl=30&shem=vslcca&source=sh/x/kp/local/m1/1&kgs=d1e64d36f9a8aa55#lkt=LocalPoiReviews&lrd=0x476353bcd2033ed9:0xc99e2210e2c718bc,3,,,,&rlfi=hd:;si:14528086903863318716,l,CgtIb3VzZSBJdmFuYZIBE3ZhY2F0aW9uX2FwcGFydG1lbnQ;mv:[[45.178748577319034,14.124120823347514],[45.17838862268097,14.123610176652488]]',
	},
};

export const reviews = [
	{
		name: 'Jenny',
		date: 'August 2024',
		rating: 5,
		comment: 'reviews.jenny',
		source: 'airbnb',
	},
	{
		name: 'Andreas',
		date: 'July 2024',
		rating: 5,
		comment: 'reviews.andreas',
		source: 'airbnb',
	},
	{
		name: 'Cassandra',
		date: 'September 2023',
		rating: 5,
		comment: 'reviews.cassandra',
		source: 'airbnb',
	},
	{
		name: 'Sarah',
		date: 'August 2021',
		rating: 5,
		comment: 'reviews.sarah',
		source: 'airbnb',
	},
	{
		name: 'Family',
		date: 'August 2015',
		rating: 5,
		comment: 'reviews.family',
		source: 'airbnb',
	},
];