<template>
	<div class="w-full">
		<label class="block text-md lg:text-lg">
			{{ label }}
		</label>
		<textarea
			:value="modelValue"
			@input="$emit('update:model', $event.target.value)"
			type="text"
			class="block w-full lg:max-w-lg bg-brown-2 rounded-md py-1 px-2 min-h-9 h-20"
		/>
	</div>
</template>

<script>
export default {
	name: 'TextInput',
	props: {
		label: {
			type: String,
			default: 'Label',
		},
		modelValue: {
			type: String,
			default: '',
		},
	},
	emits: [
		'update:model'
	],
};
</script>