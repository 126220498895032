<template>
	<svg :fill="color" :width="width" :height="height" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 315.001 315.001" xmlns:xlink="http://www.w3.org/1999/xlink" enable-background="new 0 0 315.001 315.001">
		<g>
			<path d="m136.331,167.627c0.892-3.762-1.436-7.533-5.198-8.425-5.422-1.284-13.324-13.12-12.62-18.901 0.468-3.838-2.264-7.328-6.102-7.795-3.848-0.471-7.326,2.265-7.795,6.102-1.557,12.774 11.067,31.322 23.29,34.218 0.542,0.129 1.085,0.19 1.619,0.19 3.171-2.84217e-14 6.043-2.169 6.806-5.389z"></path>
			<path d="m245.638,252.586c-1.382,0-2.74,0.095-4.077,0.261l-18.665-46.212c9.19-5.256 17.552-12.059 24.799-20.301 16.902-19.221 26.596-44.863 26.596-70.353 0-3.866-3.134-7-7-7h-186.737c-3.866,0-7,3.134-7,7 0,4.594 0.32,9.193 0.936,13.759h-30.215c-3.866,0-7,3.134-7,7 0,3.866 3.134,7 7,7h33.067c4.29,15.66 12.099,30.417 22.808,42.594 7.03,7.995 15.105,14.643 23.973,19.831l-38.975,99.276c-1.413,3.599 0.359,7.661 3.958,9.073 0.84,0.33 1.705,0.486 2.556,0.486 2.795,0 5.435-1.686 6.518-4.444l8.851-22.544h106.824c2.125,15.217 15.564,26.986 31.784,26.986 17.693,0 32.088-13.999 32.088-31.206-0.001-17.207-14.395-31.206-32.089-31.206zm-134.974-75.496c-13.138-14.94-21.263-34.34-22.831-54.108h172.18c-1.568,19.769-9.693,39.168-22.831,54.108-5.963,6.78-14.363,14.241-25.578,19.804-0.056,0.021-0.112,0.036-0.168,0.059-0.346,0.14-0.668,0.311-0.979,0.496-9.942,4.734-22.03,7.964-36.533,7.964-14.852,0-27.179-3.381-37.255-8.304-0.38-0.243-0.788-0.455-1.225-0.626-0.014-0.005-0.028-0.009-0.042-0.015-10.794-5.514-18.926-12.768-24.738-19.378zm26.075,35.249c11.556,4.643 24.099,7.073 37.185,7.073 12.76,0 25.004-2.308 36.318-6.727l18.109,44.837c-6.135,3.833-10.844,9.638-13.176,16.49h-102.649l24.213-61.673zm108.899,88.66c-9.973,0-18.086-7.719-18.086-17.206 0-9.488 8.113-17.207 18.086-17.207 9.974,0 18.088,7.719 18.088,17.207-5.68434e-14,9.488-8.114,17.206-18.088,17.206z"></path>
			<path d="m117.805,86.577c-3.188,2.188-3.997,6.546-1.809,9.732 1.356,1.977 3.548,3.039 5.776,3.039 1.366,0 2.746-0.399 3.956-1.23 22.855-15.691 12.489-32.558 7.507-40.664-5.507-8.957-7.852-13.994 3.503-21.79 3.188-2.188 3.997-6.546 1.809-9.732-2.188-3.188-6.548-3.994-9.732-1.809-22.857,15.693-12.488,32.559-7.506,40.662 5.506,8.958 7.85,13.997-3.504,21.792z"></path> <path d="m212.007,86.577c-3.188,2.188-3.997,6.545-1.809,9.732 1.356,1.977 3.548,3.039 5.776,3.039 1.365,0 2.745-0.399 3.956-1.23 22.858-15.692 12.49-32.559 7.508-40.663-5.506-8.958-7.851-13.996 3.504-21.791 3.188-2.188 3.997-6.545 1.809-9.732-2.189-3.189-6.546-3.997-9.732-1.809-22.856,15.691-12.489,32.557-7.507,40.663 5.505,8.957 7.85,13.995-3.505,21.791z"></path> <path d="m159.773,86.577c-3.187,2.188-3.996,6.546-1.808,9.733 1.357,1.976 3.548,3.038 5.776,3.038 1.366,0 2.746-0.399 3.957-1.23 29.22-20.065 15.917-41.712 9.525-52.114-7.916-12.885-11.495-21.552 5.52-33.234 3.188-2.188 3.997-6.546 1.809-9.732-2.188-3.188-6.549-3.994-9.732-1.809-29.214,20.058-15.915,41.702-9.523,52.104 7.918,12.887 11.499,21.556-5.524,33.244z"></path>
		</g>
	</svg>
</template>

<script>
export default {
	name: 'GrillIcon',
	props: {
		width: {
			type: Number,
			default: 30,
		},
		height: {
			type: Number,
			default: 41,
		},
		color: {
			type: String,
			default: 'white',
		},
	},
}
</script>