<template>
	<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" :width="width" :height="height" viewBox="0 0 256 256" xml:space="preserve">
		<g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
			<path d="M 86.001 39.308 c -1.063 0 -2.125 -0.421 -2.912 -1.257 C 73.085 27.436 59.558 21.589 45 21.589 c -14.559 0 -28.085 5.847 -38.089 16.462 c -1.515 1.607 -4.046 1.683 -5.654 0.168 c -1.608 -1.515 -1.683 -4.046 -0.168 -5.654 C 12.62 20.328 28.214 13.589 45 13.589 c 16.785 0 32.38 6.739 43.911 18.976 c 1.515 1.607 1.439 4.139 -0.168 5.654 C 87.971 38.947 86.984 39.308 86.001 39.308 z" :fill="color" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
			<path d="M 74.416 50.398 c -1.064 0 -2.125 -0.422 -2.912 -1.257 C 64.525 41.735 55.113 37.657 45 37.657 c -10.112 0 -19.525 4.079 -26.504 11.485 c -1.516 1.606 -4.047 1.684 -5.654 0.168 c -1.608 -1.515 -1.683 -4.046 -0.168 -5.654 C 21.18 34.628 32.66 29.657 45 29.657 s 23.82 4.972 32.326 13.999 c 1.515 1.608 1.439 4.14 -0.168 5.654 C 76.386 50.037 75.399 50.398 74.416 50.398 z" :fill="color" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
			<path d="M 63.386 61.588 c -1.063 0 -2.125 -0.422 -2.912 -1.257 c -4.075 -4.325 -9.571 -6.707 -15.474 -6.707 c -5.903 0 -11.398 2.382 -15.473 6.707 c -1.515 1.606 -4.045 1.684 -5.654 0.168 c -1.608 -1.515 -1.683 -4.046 -0.168 -5.654 C 29.307 48.898 36.87 45.624 45 45.624 c 8.13 0 15.693 3.274 21.296 9.221 c 1.515 1.607 1.439 4.14 -0.168 5.654 C 65.355 61.228 64.369 61.588 63.386 61.588 z" :fill="color" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
			<circle cx="44.998" cy="68.918" r="4.498" :fill="color" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill-rule: nonzero; opacity: 1;" transform="  matrix(1 0 0 1 0 0) "/>
		</g>
	</svg>
</template>

<script>
export default {
	name: 'WifiIcon',
	props: {
		width: {
			type: Number,
			default: 30,
		},
		height: {
			type: Number,
			default: 41,
		},
		color: {
			type: String,
			default: 'white',
		},
	},
}
</script>