import axios from 'axios';

const axiosInstance = axios.create({
	baseURL: 'https://www.emailer.studio31.hr/api',
});

export default {
	get(url, config = {}) {
		try {
			return axiosInstance.get(url, config);
		} catch (error) {
			console.error(error);
		}
	},

	post(url, data, config = {}) {
		try {
			return axiosInstance.post(url, data, config);
		} catch (error) {
			console.error(error);
		}
	},
};
