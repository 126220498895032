<template>
	<div
		v-if="galleryOpen"
		class="fixed w-screen h-dvh top-0 left-0 z-30"
	>
		<div class="w-full h-full absolute top-0 left-0 bg-black bg-opacity-60" @click="closeGallery"></div>

		<div class="w-full h-full relative">
			<div class="absolute top-5 right-5 cursor-pointer z-20" @click="closeGallery">
				<span class="text-white text-5xl">
					&#215;
				</span>
			</div>

			<div
				class="w-full h-full grid grid-rows-[80%,20%]"
				@click="closeGallery"
			>
				<!-- Display the selected image in the center -->
				<div
					class="relative flex justify-center items-center"
					@touchstart="handleTouchStart"
					@touchmove="handleTouchMove"
				>
					<div class="w-full h-full z-10 flex justify-center items-center overflow-hidden">
						<img
							@click="stopPropagation"
							:src="images[selectedImage]"
							:alt="`Image ${selectedImage + 1}`"
							class="max-w-full max-h-full object-contain"
						/>
					</div>

					<div class="absolute z-10 top-0 left-0 w-fit h-full px-2 grid">
						<div class="w-full h-full md:h-fit grid place-content-center my-auto" @click="prevImage">
							<div
								class="cursor-pointer p-0 md:p-2 transition-all hover:scale-[1.1] active:scale-[0.95]"
							>
								<ArrowLeftIcon :width="30" :height="30" />
							</div>
						</div>
					</div>
					<div class="absolute z-10 top-0 right-0 w-fit h-full px-2 grid">
						<div class="w-full h-full md:h-fit grid place-content-center my-auto" @click="nextImage" >
							<div class="cursor-pointer p-0 md:p-2 transition-all hover:scale-[1.1] active:scale-[0.95]">
								<ArrowRightIcon :width="30" :height="30" />
							</div>
						</div>
					</div>
				</div>

				<!-- Thumbnail list with horizontal scrolling -->
				<div class="flex justify-start items-center overflow-x-auto gap-x-4 py-4 h-full min-w-0">
					<div
						v-for="(image, index) in images"
						:key="index"
						class="
							cursor-pointer transform transition-transform duration-200 hover:scale-105 w-24 h-24 rounded
							shrink-0
							bg-cover bg-center bg-no-repeat
						"
						:style="{ backgroundImage: `url(${image})` }"
						@click="(e) => selectImage(e, index)"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { images } from '../../assets/config/galleryConfig';
import ArrowLeftIcon from '@/components/icons/ArrowLeftIcon.vue';
import ArrowRightIcon from '@/components/icons/ArrowRightIcon.vue';

export default {
	name: 'PhotoGallery',
	setup() {
		return {
			images,
		};
	},
	props: {
		galleryOpen: {
			type: Boolean,
			required: true,
		},
	},
	emits: [
		'close',
	],
	components: {
		ArrowLeftIcon,
		ArrowRightIcon,
	},
	data() {
		return {
			selectedImage: 0,

			startX: null,
			mouseStartX: null,
		};
	},
	mounted() {
		document.addEventListener('keydown', (e) => {
			if (this.galleryOpen) {
				if (e.key === 'ArrowRight') {
					this.nextImage(e);
				} else if (e.key === 'ArrowLeft') {
					this.prevImage(e);
				} else if (e.key === 'Escape') {
					this.closeGallery();
				}
			}
		});
	},
	methods: {
		closeGallery() {
			this.$emit('close');
		},
		selectImage(e, image) {
			this.stopPropagation(e);
			this.selectedImage = image;
		},
		nextImage(e) {
			this.stopPropagation(e);
			if (this.selectedImage < this.images.length - 1) {
				this.selectedImage++;
			} else {
				this.selectedImage = 0;
			}
		},
		prevImage(e) {
			this.stopPropagation(e);
			if (this.selectedImage > 0) {
				this.selectedImage--;
			} else {
				this.selectedImage = this.images.length - 1;
			}
		},
		stopPropagation(e) {
			e.stopPropagation();
		},
		handleTouchStart(event) {
			this.startX = event.touches[0].clientX;
		},
		handleTouchMove(event) {
			if (!this.startX) {
				return;
			}

			const moveX = event.touches[0].clientX;
			const diffX = moveX - this.startX;

			if (diffX > 50) {
				this.prevImage(event);
				this.startX = null;
			} else if (diffX < -50) {
				this.nextImage(event);
				this.startX = null;
			}
		},
	},
	watch: {
		galleryOpen() {
			if (this.galleryOpen) {
				document.body.style.overflow = 'hidden';
			} else {
				document.body.style.overflow = 'auto';
			}
		},
	},
};
</script>