<template>
	<div class="flex justify-center items-center gap-2 w-fit h-fit">
		<CountryFlag
			v-for="locale in availableLocales"
			:key="locale"
			:country="locale"
			size="normal"
			class="cursor-pointer transition-all hover:border-4 border-black"
			@click="$i18n.locale = locale"
		/>
	</div>
</template>

<script>
import CountryFlag from 'vue-country-flag-next';

export default {
	name: 'LanguageSelector',
	components: {
		CountryFlag,
	},
	computed: {
		availableLocales() {
			return this.$i18n.availableLocales;
		},
		currentLocale() {
			return this.$i18n.locale;
		},
	},
};
</script>