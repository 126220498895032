<template>
	<div>
		<div class="fixed top-0 right-5 w-fit h-fit z-[30]">
			<LanguageSelector />
		</div>
		<LandingPage />
		<AboutPage />
		<GalleryPage />
		<RequestPage />
		<InfoPage />
	</div>
</template>

<script>
import LandingPage from '../components/LandingPage.vue';
import LanguageSelector from '../components/graphics/LanguageSelector.vue';
import GalleryPage from '../components/GalleryPage.vue';
import RequestPage from '../components/RequestPage.vue';
import InfoPage from '../components/InfoPage.vue';
import AboutPage from '../components/AboutPage.vue';

export default {
	name: 'HomeView',
	components: {
		LandingPage,
		GalleryPage,
		RequestPage,
		InfoPage,
		AboutPage,
		LanguageSelector,
	},
}
</script>