<template>
	<div class="h-full flex justify-center items-center gap-6 absolute top-0 right-0 -translate-x-1/3 z-0 opacity-10 lg:opacity-100">
		<div 
			v-for="index in lineNumber"
			:key="index"
			class="w-3 h-full"
			:style="{ backgroundColor: color }"
		></div>
	</div>
</template>

<script>
import tailwindConfig from '../../../tailwind.config.js';

export default {
	name: 'VerticalLines',
	props: {
		lines: {
			type: Number,
			default: 6,
		},
		color: {
			type: String,
			default: tailwindConfig.theme.extend.colors['blue-2'],
		},
	},
	data() {
		return {
			lineNumber: this.lines,
		};
	},
	mounted() {
		if (document.body.clientWidth < 1024 && this.lines === 6) {
			this.lineNumber = 3;
		}
	}
};
</script>