<template>
	<svg :width="width" :height="height" viewBox="0 0 30 41" fill="none" xmlns="http://www.w3.org/2000/svg">
		<circle cx="15.3668" cy="10.2439" r="10.2439" :fill="color"/>
		<path d="M0 27.4153C0 25.2061 1.79086 23.4153 4 23.4153H26C28.2091 23.4153 30 25.2061 30 27.4153V40.2445H0V27.4153Z" :fill="color"/>
	</svg>
</template>

<script>
export default {
	name: 'ProfileIcon',
	props: {
		width: {
			type: Number,
			default: 30,
		},
		height: {
			type: Number,
			default: 41,
		},
		color: {
			type: String,
			default: 'white',
		},
	},
}
</script>